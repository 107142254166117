<template>
  <!-- 开通Vip相关Model -->
  <el-dialog
    :visible.sync="$store.state.vip.payErrorTipsStatus"
    :close-on-click-modal="false"
    customClass="payErrorClass"
  >
    <div class="tips-text">
      <span class="iconfont_Me icon-remind"></span
      >{{ $t("insufficientBalance") }}
    </div>
    <div class="err-btn commomButtonHover" @click="toWallet">
      {{ $t("recharge") }}
    </div>
  </el-dialog>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapMutations([
      "setVipModeStatus",
      "setPayErrorTipsStatus",
      "setIsVipModeStatus",
      "setRobotChatDescription",
    ]),
    toWallet() {
      this.setPayErrorTipsStatus(false);
      this.setVipModeStatus(false);
      this.setIsVipModeStatus(false);
      this.setRobotChatDescription({ visible: false });
      this.routeJump({ name: "wallet", params: { type: "topUp" } });
    },
  },
};
</script>
<style lang="scss" scoped>
:deep .payErrorClass {
  width: 342px;
  height: 204px;
  background: #ffffff;
  border-radius: 8px;

  .el-dialog__headerbtn {
    top: 16px;
  }

  .el-dialog__body {
    height: calc(100% - 30px);
    text-align: center;
    font-size: 18px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    font-size: 16px;

    .tips-text {
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .icon-remind {
        margin-right: 10px;
        color: #ff8a00;
        font-size: 24px;
      }
    }

    .err-btn {
      width: 100%;
      height: 46px;
      line-height: 46px;
      color: #fff;
      letter-spacing: 2px;
      background: #33cc66;
      border-radius: 8px;
      margin-top: auto;
      cursor: pointer;
    }
  }
}
</style>
