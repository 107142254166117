var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.$store.state.vip.payErrorTipsStatus,
        "close-on-click-modal": false,
        customClass: "payErrorClass"
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(_vm.$store.state.vip, "payErrorTipsStatus", $event)
        }
      }
    },
    [
      _c("div", { staticClass: "tips-text" }, [
        _c("span", { staticClass: "iconfont_Me icon-remind" }),
        _vm._v(_vm._s(_vm.$t("insufficientBalance")) + "\n  ")
      ]),
      _c(
        "div",
        {
          staticClass: "err-btn commomButtonHover",
          on: { click: _vm.toWallet }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("recharge")) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }